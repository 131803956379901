import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--secondary-text);
  width: 128px;
  cursor: pointer;
  box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px 0px rgba(250, 250, 250, 0.3);
  transition: .1s;
  :hover{
    transform: translate3d(0, 4px, 0);
    box-shadow: none;
  }
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  }
`;

export const StyledButtonS = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: rgba(250, 250, 250, 0.4);
  font-weight: bold;
  color: var(--secondary-text);
  width: 128px;
  
    box-shadow: none;
  
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 915px) {
    flex-direction: row;
  }
`;

export const StyledLink = styled.a`
  color: #fff;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const mintAmount = 1;
  const [totalSupply_0, settotalSupply0] = useState(0);
  const [totalSupply_1, settotalSupply1] = useState(0);
  const [totalSupply_2, settotalSupply2] = useState(0);
  const [totalSupply_3, settotalSupply3] = useState(0);
  const [totalSupply_4, settotalSupply4] = useState(0);
  const [claimingNft_0, setClaimingNft0] = useState(false);
  const [claimingNft_1, setClaimingNft1] = useState(false);
  const [claimingNft_2, setClaimingNft2] = useState(false);
  const [claimingNft_3, setClaimingNft3] = useState(false);
  const [claimingNft_4, setClaimingNft4] = useState(false);
  const [feedback_0, setFeedback0] = useState(``);
  const [feedback_1, setFeedback1] = useState(``);
  const [feedback_2, setFeedback2] = useState(``);
  const [feedback_3, setFeedback3] = useState(``);
  const [feedback_4, setFeedback4] = useState(``);
  
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY_0: 1,
    MAX_SUPPLY_1: 1,
    MAX_SUPPLY_2: 1,
    MAX_SUPPLY_3: 1,
    MAX_SUPPLY_4: 1,
    WEI_COST_0: 0,
    WEI_COST_1: 0,
    WEI_COST_2: 0,
    WEI_COST_3: 0,
    WEI_COST_4: 0,
    DISPLAY_COST_0: 0,
    DISPLAY_COST_1: 0,
    DISPLAY_COST_2: 0,
    DISPLAY_COST_3: 0,
    DISPLAY_COST_4: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  //supply check
  const checkTotalSupply0 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(0)
      .call()
      .then((receipt) => {
        settotalSupply0(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply1 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(1)
      .call()
      .then((receipt) => {
        settotalSupply1(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply2 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(2)
      .call()
      .then((receipt) => {
        settotalSupply2(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply3 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(3)
      .call()
      .then((receipt) => {
        settotalSupply3(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  const checkTotalSupply4 = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
      .totalSupply(4)
      .call()
      .then((receipt) => {
        settotalSupply4(receipt);
        dispatch(fetchData(blockchain.account));
      });
    }
  };

  //mint
  const claimNFT0 = () => {
    let cost = CONFIG.WEI_COST_0;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback0(`ミント中です...`);
    setClaimingNft0(true);
    blockchain.smartContract.methods
      .publicMint(0, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback0("失敗したので再度試してみてください！");
        setClaimingNft0(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback0(
          `ミントが成功しました！`
        );
        setClaimingNft0(false);
        checkTotalSupply0();
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFT1 = () => {
    let cost = CONFIG.WEI_COST_1;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback1(`ミント中です...`);
    setClaimingNft1(true);
    blockchain.smartContract.methods
      .publicMint(1, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback1("失敗したので再度試してみてください！");
        setClaimingNft1(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback1(
          `ミントが成功しました！`
        );
        setClaimingNft1(false);
        checkTotalSupply1();
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFT2 = () => {
    let cost = CONFIG.WEI_COST_2;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback2(`ミント中です...`);
    setClaimingNft2(true);
    blockchain.smartContract.methods
      .publicMint(2, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback2("失敗したので再度試してみてください！");
        setClaimingNft2(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback2(
          `ミントが成功しました！`
        );
        setClaimingNft2(false);
        checkTotalSupply2();
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFT3 = () => {
    let cost = CONFIG.WEI_COST_3;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback3(`ミント中です...`);
    setClaimingNft3(true);
    blockchain.smartContract.methods
      .publicMint(3, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback3("失敗したので再度試してみてください！");
        setClaimingNft3(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback3(
          `ミントが成功しました！`
        );
        setClaimingNft3(false);
        checkTotalSupply3();
        dispatch(fetchData(blockchain.account));
      });
  };


  //BigInt使用
  const claimNFT4 = () => {
    let cost = BigInt(CONFIG.WEI_COST_4);
    let gasLimit = BigInt(CONFIG.GAS_LIMIT);
    let mintAmount = BigInt(1);//BigIntに揃える
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback4(`ミント中です...`);
    setClaimingNft4(true);
    blockchain.smartContract.methods
      .publicMint(4, mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback4("失敗したので再度試してみてください！");
        setClaimingNft4(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback4(
          `ミントが成功しました！`
        );
        setClaimingNft4(false);
        checkTotalSupply4();
        dispatch(fetchData(blockchain.account));
      });
  };



  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply0(); 
    checkTotalSupply1();
    checkTotalSupply2();
    checkTotalSupply3();
    checkTotalSupply4();
  }, []);

  useEffect(() => {
    getData();
    getConfig();
    checkTotalSupply0(); 
    checkTotalSupply1();
    checkTotalSupply2();
    checkTotalSupply3();
    checkTotalSupply4();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.TextDescription style={{ position: 'fixed', width: '100%', fontSize: 18, padding: "6px 5px", textAlign: "left", color: "#fff", backgroundColor: "rgba(0, 0, 0)"}}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledLink style={{ paddingTop:"7px",color: "#fff", margin: "0px 0px 0px 16px" }} href="">
            恩を売れるNFT
          </StyledLink>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: "16px" }}>
            <a href="https://opensea.io/collection/nft-to-ingratiate-itself-with-nft-prince" target="_blank" rel="noopener" style={{ marginRight: "16px" }}>
              <img style={{ width: "30px",marginTop:"5px" }} src="/config/images/icon_opensea.png" alt="OpenSea Icon"></img>
            </a>
            <a href="https://twitter.com/nftouji" target="_blank" rel="noopener">
              <img style={{ width: "30px",marginTop:"5px" }} src="/config/images/icon_twitter.png" alt="Twitter Icon"></img>
            </a>
          </div>
        </div>
      </s.TextDescription>

      <s.TextDescription>
        <img style={{maxWidth: "75%", textAlign: "center",padding: "60px 0px 0px 0px", textAlign: "center"}}  src="/config/images/top.JPG" alt="top" />
      </s.TextDescription>
      
        <s.TextDescription
        style={{textAlign:"center",fontSize: 48, fontWeight: "bold", margin: '10px', padding: "20px", color: "#000", fontFamily: "DM+Serif+Display"}}
      >
        "恩を売れる"
        </s.TextDescription>
      <s.TextDescription
        style={{fontSize: "18px", padding: "5px 40px 30px 40px",textAlign: "center", color: "#000", fontWeight: "bold", fontFamily: "DM+Serif+Display"}}
      >
        ただそれだけのNFT。
      </s.TextDescription>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 15, backgroundColor: "rgba(0, 0, 0, 0)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >

      <ResponsiveWrapper flex={1} style={{ padding: '0px 20px 20px 0px', }}>
        <s.Container
          flex={2}
          jc={"center"}
          ai={"center"}
          style={{
            backgroundColor: "#B7930A",
            margin: 10,
            padding: 10,
            borderRadius: 24,
            border: "none",
            boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
          }}
        >
          <s.TextTitle
            style={{
                textAlign: "center",
                fontSize: 45,
                color: "var(--accent-text)",
                fontFamily: "DM+Serif+Display",
            }}
          >
            {totalSupply_0} / {CONFIG.MAX_SUPPLY_0}
          </s.TextTitle>
          <img style={{maxWidth: "100%",margin:"25px"}} src="/config/images/0.jpg"></img>
          <s.TextTitle
            style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
          >
            - NFT王子水道局 -
          </s.TextTitle>
          {Number(totalSupply_0) >= CONFIG.MAX_SUPPLY_0 ? (
          <>
            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
            >
              完売御礼
            </s.TextDescription>
            <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
              →{CONFIG.MARKETPLACE}
            </StyledLink>
          </>
          ) : (
            <>
              {blockchain.account === "" || blockchain.smartContract === null ? (
                <s.Container ai={"center"} jc={"center"}>
                <s.SpacerSmall />
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  CONNECT
                </StyledButton>
                    
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                      }}
                    >
                    {blockchain.errorMsg}
                    </s.TextDescription>
                  </>
                  ) : null}
                  </s.Container>
                    ) : (
                      <>
                      <s.TextDescription
                        style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        }}
                      >
                      {feedback_0}
                      </s.TextDescription>
                      {/* セール0ここから */}
              
                          <>
                          <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButton
                                disabled={claimingNft_0 ? 1 : 0}//claimingNft0がtrueなら disabled
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFT0();
                                  getData();
                                }}
                              >
                                  {claimingNft_0 ? "MINTING" : "MINT"}
                              </StyledButton>
                            </s.Container>
                          </>

                      {/* セール0ここまで */}
                                </>
                                )}
                              </>
                              )}
                            <s.SpacerSmall />
                    <s.TextTitle
                        style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                    >35MATIC
                    </s.TextTitle>
              <s.SpacerMedium />
          </s.Container>
          
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "#B7930A",
              margin: 10,
              padding: 10,
              borderRadius: 24,
              border: "none",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 45,
                color: "var(--accent-text)",
                fontFamily: "DM+Serif+Display",
              }}
            >
              {totalSupply_1} / {CONFIG.MAX_SUPPLY_1}
            </s.TextTitle>
              <img style={{maxWidth: "100%",margin:"25px"}} src="/config/images/1.jpg"></img>
            <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >- NFT王子ガス -
                </s.TextTitle>
            {Number(totalSupply_1) >= CONFIG.MAX_SUPPLY_1 ? (
              <>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >
                  完売御礼
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  →{CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" || blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback_1}
                    </s.TextDescription>
                    {/* セール1ここから */}

                        <>
                            <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButton
                                disabled={claimingNft_1 ? 1 : 0}//claimingNft1がtrueなら disabled
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFT1();
                                  getData();
                                }}
                              >
                              {claimingNft_1 ? "MINTING" : "MINT"}
                              </StyledButton>
                            </s.Container>

                        </>
                      
                      {/* セール1ここまで */}
                  </>
                )}
              </>
            )}
            <s.SpacerSmall />
                    
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >58MATIC
                </s.TextTitle>
                
            <s.SpacerMedium />
          </s.Container>
          
        </ResponsiveWrapper>

        
        <ResponsiveWrapper flex={1} style={{ padding: '0px 20px 20px 0px', }}>
          
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "#B7930A",
              margin: 10,
              padding: 10,
              borderRadius: 24,
              border: "none",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 45,
                color: "var(--accent-text)",
                fontFamily: "DM+Serif+Display",
              }}
            >
              完売御礼!
            </s.TextTitle>
              <img style={{maxWidth: "25%",margin:"25px"}} src="/config/images/2.jpg"></img>
            <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >- お食事券 -
                </s.TextTitle>
            {Number(totalSupply_2) >= CONFIG.MAX_SUPPLY_2 ? (
              <>
              <s.SpacerSmall />
                <StyledButtonS
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      SOLD OUT
                    </StyledButtonS>
              </>
            ) : (
              <>
                {blockchain.account === "" || blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButtonS
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      SOLD OUT
                    </StyledButtonS>
                    
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback_2}
                    </s.TextDescription>
                    {/* セール2ここから */}
                    
                        <>
                          <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButton
                                disabled={claimingNft_2 ? 1 : 0}//claimingNft2がtrueなら disabledを表示させる。＝クリックできない
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFT2();
                                  getData();
                                }}
                              >
                              {claimingNft_2 ? "MINTING" : "MINT"}
                              </StyledButton>
                            </s.Container>
                            
                          </>

                      {/* セール2ここまで */}

                  </>
                )}
              </>
            )}
            <s.SpacerSmall />
                    
                    <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display",textDecoration:"line-through"}}
                >115MATIC
                </s.TextTitle>
            <s.SpacerMedium />
          </s.Container>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "#B7930A",
              margin: 10,
              padding: 10,
              borderRadius: 24,
              border: "none",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 45,
                color: "var(--accent-text)",
                fontFamily: "DM+Serif+Display",
              }}
            >
              {totalSupply_3} / {CONFIG.MAX_SUPPLY_3}
            </s.TextTitle>
              <img style={{maxWidth: "100%",margin:"25px"}} src="/config/images/3.jpg"></img>
            <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >- NFT王子電力 -
                </s.TextTitle>
            {Number(totalSupply_3) >= CONFIG.MAX_SUPPLY_3 ? (
              <>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >
                  完売御礼
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  →{CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" || blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback_3}
                    </s.TextDescription>
                    {/* セール3ここから */}
                   
                          <>
                          <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButton
                                disabled={claimingNft_3 ? 1 : 0}//claimingNft3がtrueなら disabled
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFT3();
                                  getData();
                                }}
                              >
                              {claimingNft_3 ? "MINTING" : "MINT"}
                              </StyledButton>
                            </s.Container>
                            
                          </>

                      {/* セール3ここまで */}

                  </>
                )}
              </>
            )}
            <s.SpacerSmall />
                    
                    <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >345MATIC
                </s.TextTitle>
                
            <s.SpacerMedium />
          </s.Container>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "#B7930A",
              margin: 10,
              padding: 10,
              borderRadius: 24,
              border: "none",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.5)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 45,
                color: "var(--accent-text)",
                fontFamily: "DM+Serif+Display",
              }}
            >
              {totalSupply_4} / {CONFIG.MAX_SUPPLY_4}
            </s.TextTitle>
              <img style={{maxWidth: "100%",margin:"25px"}} src="/config/images/4.jpg"></img>
            <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >- NFT王子不動産 -
                </s.TextTitle>
            {Number(totalSupply_4) >= CONFIG.MAX_SUPPLY_4 ? (
              <>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >
                  完売御礼
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  →{CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" || blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback_4}
                    </s.TextDescription>
                    {/* セール4ここから */}

                        <>                      
                            <s.SpacerSmall />
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButton
                                disabled={claimingNft_4 ? 1 : 0}//claimingNft4がtrueなら disabled
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFT4();
                                  getData();
                                }}
                              >
                              {claimingNft_4 ? "MINTING" : "MINT"}
                              </StyledButton>
                            </s.Container>
                        </>
                      
                      {/* セール4ここまで */}

                  </>
                )}
              </>
            )}
            <s.SpacerSmall />
                    
                    <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)", fontFamily: "DM+Serif+Display"}}
                >1150MATIC
                </s.TextTitle>
                
            <s.SpacerMedium />
          </s.Container>
        </ResponsiveWrapper>
        <StyledLink style={{ paddingTop:"3px",color: "#000", margin: "0px 0px 0px 16px",textDecoration:"underline",fontWeight:"bold" }} target="_blank" rel="noopener" href="https://forms.gle/yArJAyprdKDMgW6a9">
            日本円かイーサで購入希望の方はこちらから申請してください
          </StyledLink>
        <s.SpacerMedium />
      </s.Container>
    </s.Screen>
  );
}
export default App;